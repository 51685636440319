<template> 
    <div class="page-content header-clear-small">

        <div class="card card-style" v-if="!loading && !islogout">
            <div class="content mb-0">
                <h4 class="mb-4">Mon compte<span class="float-right"><a href="#" @click.prevent="logout" class="btn bg-red1-dark text-uppercase rounded-sm shadow-l mb-3 mt-4 font-900" style="padding: 4px 10px;"><i class="fas fa-sign-out-alt"></i></a></span></h4>      
                
                <div class="input-style input-style-2 has-icon input-required">
                    <i class="input-icon fa fa-user"></i>
                    <span class="color-highlight input-style-1-active font-16">Prénom</span>
                    <em>(Obligatoire)</em>
                    <input type="text" class="form-control" value="Prénom" :class="{'is-invalid': errors.firstname}" v-model="user.firstname">
					<div class="invalid-feedback" v-if="errors.firstname">{{errors.firstname[0]}}</div>
                </div>	
				<div class="input-style input-style-2 has-icon input-required">
                    <i class="input-icon fa fa-user"></i>
                    <span class="color-highlight input-style-1-active font-16">Nom</span>
                    <em>(Obligatoire)</em>
					<input type="text" class="form-control" :class="{'is-invalid': errors.name}" v-model="user.name">
					<div class="invalid-feedback" v-if="errors.name">{{errors.name[0]}}</div>
				</div> 				
                <div class="input-style input-style-2 has-icon input-required mt-4">
                    <i class="input-icon fa fa-at"></i>
                    <span class="color-highlight input-style-1-active font-16">Email</span>
                    <em>(Obligatoire)</em>
                    <input type="email" class="form-control" :class="{'is-invalid': errors.email}" v-model="user.email">
					<div class="invalid-feedback" v-if="errors.email">{{errors.email[0]}}</div>
                </div> 
                <div class="input-style input-style-2 has-icon input-required mt-4">
                    <i class="input-icon fa fa-map-marker"></i>
                    <span class="color-highlight input-style-1-active font-16">Adresse</span>
                    
                    <input type="text" class="form-control" :class="{'is-invalid': errors.address}" v-model="user.address">
					<div class="invalid-feedback" v-if="errors.address">{{errors.address[0]}}</div>
                </div> 				
                <div class="input-style input-style-2 has-icon input-required mt-4">
                    <i class="input-icon fa fa-map-marker"></i>
                    <span class="color-highlight input-style-1-active font-16">Code postal</span>
                    <em>(Obligatoire)</em>
                    <input type="text" class="form-control" :class="{'is-invalid': errors.zip}" v-model="user.zip">
					<div class="invalid-feedback" v-if="errors.zip">{{errors.zip[0]}}</div>
                </div> 
                <div class="input-style input-style-2 has-icon input-required mt-4">
                    <i class="input-icon fa fa-map-marker"></i>
                    <span class="color-highlight input-style-1-active font-16">Ville</span>
                    <em>(Obligatoire)</em>
                    <input type="text" class="form-control" :class="{'is-invalid': errors.city}" v-model="user.city">
					<div class="invalid-feedback" v-if="errors.city">{{errors.city[0]}}</div>
                </div> 
                <div class="input-style input-style-2 has-icon input-required mt-4">
                    <i class="input-icon fa fa-phone"></i>
                    <span class="color-highlight input-style-1-active font-16">Mobile</span>
                    <input type="number" class="form-control" :class="{'is-invalid': errors.phone}" v-model="user.phone">
					<div class="invalid-feedback" v-if="errors.phone">{{errors.phone[0]}}</div>
                </div> 				
                <a href="#" @click.prevent="updateAuthUser" class="btn btn-full bg-green1-dark btn-m text-uppercase rounded-sm shadow-l mb-3 mt-4 font-900">Enregistrer</a>
            </div>
        </div>         
        
        <div class="card card-style" v-if="!loading && !islogout">
            <div class="content mb-0">
                <h4 class="mb-4">Changer mon mot de passe</h4>
				
                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active font-16">Mot de passe actuel</span>
                    <em>(Obligatoire)</em>
                   <input class="form-control" :class="{'is-invalid': errors.current}" type="password" v-model="password.current">
                
					<div class="invalid-feedback" v-if="errors.current">{{errors.current[0]}}</div>
					
                </div> 
                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active font-16">Nouveau mot de passe</span>
                    <em>(Obligatoire)</em>
                    <input class="form-control" :class="{'is-invalid': errors.password}" type="password" v-model="password.password">
					<div class="invalid-feedback" v-if="errors.password">{{errors.password[0]}}</div>
                </div> 
                <div class="input-style input-style-2 input-required">
                    <span class="color-highlight input-style-1-active font-16">Confirmation</span>
                    <em>(Obligatoire)</em>
                    <input class="form-control" :class="{'is-invalid': errors.password_confirmation}" type="password" v-model="password.password_confirmation">
					<div class="invalid-feedback" v-if="errors.password_confirmation">{{errors.password_confirmation[0]}}</div>
                </div> 				
                <a href="#" @click.prevent="updatePassword" class="btn btn-full bg-green1-dark btn-m text-uppercase rounded-sm shadow-l mb-3 mt-4 font-900">Enregistrer</a>
            <p class="color-red font-16 mt-4">Si vous modifiez votre mot de passe, vous serez déconnecté de votre compte.</p>
			</div>
        </div>
		
<!-- dark mode -->	
		
<!-- delete account-->		
        <div class="card card-style" v-if="!loading && !islogout">
            <div class="content mb-0">
                <h4 class="mb-4">Supprimer mon compte</h4>
				<p class="color-red font-16">Vous ne pouvez pas rouvrir un compte une fois que celui-ci a été supprimé.</p>			
                <a href="#" @click.prevent="showNotification('notification-1')" class="btn btn-full bg-red-dark-sdw btn-m text-uppercase color-white rounded-sm shadow-l mb-3 mt-4 font-900">Supprimer mon compte</a>
            </div>
        </div> 		
<!-- delete account -->		
		<div class="card card-style mt-4" v-if="islogout">
			<h4 class="text-center text-warning pt-4 pb-4">Déconnexion</h4>
		</div>
        <Footer v-if="!loading"></Footer>
    <div id="notification-1" data-dismiss="notification-1" data-delay="3000" data-autohide="true" class="notification bg-red-dark-sdw shadow-xl fade hide">
        <div class="toast-header bg-red-dark-sdw border-highlight">
            <strong class="mr-auto color-white">Supprimer mon compte</strong>
            <button type="button" class="ml-2 mb-1 color-white close" @click.prevent="closeNotification('notification-1')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="toast-body bg-green-dark-sdw color-white">
            Êtes-vous sûr de vouloir supprimer définitivement votre compte ?
			<a href="#" @click.prevent="closeAccount" class="btn btn-full bg-orange-dark-sdw btn-m text-uppercase color-white rounded-sm shadow-l mb-3 mt-4 font-900 text-center" style="max-width:150px; margin: 0 auto">Oui</a>
        </div>
    </div>		
		<div class="card card-style mt-4" style="height:100vh; background-color:#f0f0f0" v-if="loading && !islogout">
            <div class="content text-center" style="margin-top:60%">
				<div class="spinner-border color-highlight" role="status"></div>
			</div>
		</div>
    </div>
    <!-- End of Page Content--> 
</template>

<script>

import AppConstants from '@/config'
export default {
  name: 'Compte',
  data() {
    return{
		user: {},
		errors: {},
		loading: true,
		submiting: false,
		islogout: false,
		password: {},
		p1:0,
		p2:[],
		tickBoxChecked: false
	}
  },
  components: {
  },
  mounted() {
  if (localStorage.getItem('isloggedin') == 1) {
    this.getAuthUser() 
	this.islogout = false
	} else {
		this.islogout = true 
	}
	
	if (localStorage.getItem('theme') == 'theme-dark') {
		this.tickBoxChecked = true
	} else {
		this.tickBoxChecked = false
	}
	
	
  },  
  methods: { 
	check(event){
		console.log(event);
		if (this.tickBoxChecked) {
			localStorage.setItem('theme','theme-dark' );		

		} else {
		localStorage.setItem('theme','theme-light' );		
		
		}
		this.$router.go(this.$router.currentRoute)

	},
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},  
	logout() {
	this.$emit('logout', '1')
	location.href = '/'
	this.islogout = true
	},
    getAuthUser () {
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"user-account"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				this.loading = true
				if (api_token) {
				
					var apiData = {
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						this.user = response.data.data
						this.p1 = response.data.p1
						this.p2 = response.data.p2
						this.p3 = response.data.p3
						localStorage.setItem('p1', this.p1)
						localStorage.setItem('p2', this.p2)
						localStorage.setItem('p3', this.p3)
						this.loading = false
					})
					.catch(error => {
						console.log(error.response.data.errors)
						this.loading = true
					})
					}
    },
	updatePreferences() {
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"update-preferences"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				if (api_token) {
				
					var apiData = {
					"p1" : this.p1,
					"p2" : this.p2,
					"p3" : this.p3,
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						if (response.data.message =='OK') {
							this.getAuthUser()
							this.errors = {}
						}
					})
					.catch(error => {
						this.errors = error.response.data.errors
					})
					}	
	
	
	},
    updateAuthUser () {
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"update-account"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				var email = this.user.email
				var firstname = this.user.firstname
				var name = this.user.name
				var zip= this.user.zip
				var city= this.user.city
				var address= this.user.address
				var phone= this.user.phone
				if (api_token) {
				
					var apiData = {
					"email" : email,
					"firstname" : firstname,
					"name" : name,
					"zip" : zip,
					"city" : city,
					"address" : address,
					"phone" : phone,
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						this.user = response.data.data
						this.errors = {}
					})
					.catch(error => {
						this.errors = error.response.data.errors
					})
					}
    },
    updatePassword () {
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"update-password"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				
				var current = this.password.current
				var password = this.password.password
				var password_confirmation = this.password.password_confirmation			

				if (api_token) {
				
					var apiData = {
					"current":current,
					"password":password,
					"password_confirmation": password_confirmation,
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						this.user = response.data.data
						this.errors = {}
						this.logout()
					})
					.catch(error => {
						this.errors = error.response.data.errors
					})
					}
    },
	showNotification(id){
		document.getElementById(id).classList.remove("hide");
		document.getElementById(id).classList.add("show");
	
	},
	closeNotification(id){
		document.getElementById(id).classList.remove("show");
		document.getElementById(id).classList.add("hide");
	
	},
	closeAccount(){
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"close-account"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				if (api_token) {
					var apiData = {
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						if (response.data.message == 'OK') {
						console.log(response.data.data)
						this.errors = {}
						this.logout()
						}
					})
					.catch(error => {
						this.errors = error.response.data.errors
					})
					}
	},	
  }
}


</script>
<style>
.float-right{float:right;}
</style>
